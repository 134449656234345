import { useEffect, useState } from "react";
import styles from "./Broadcast.module.scss";
import { toast } from "react-toastify";
import { fetchBroadcast, fetchGetTasks } from "../../api/admin";
import cl from "classnames";
import { ITask, ITaskType } from "../../types/admin";
import trash from "../../images/svg/trash.svg";

export const Broadcast = () => {
	const [messageEn, setMessageEn] = useState<string>("example");
	const [messageRu, setMessageRu] = useState<string>("пример");
	const [messageAllEn, setMessageAllEn] = useState<string>(
		"You now have access to _ quests. Head over to the bot and start completing them quickly!"
	);
	const [messageAllRu, setMessageAllRu] = useState<string>(
		"Теперь у вас есть доступ к _ заданиям. Заходите в бота и начинайте их быстро выполнять!"
	);
	const [password, setPassword] = useState<string>("");
	const [buttonTextEn, setButtonTextEn] = useState<string>();
	const [buttonTextRu, setButtonTextRu] = useState<string>();
	const [imageUrl, setImageUrl] = useState<string>();
	const [inlineButtons, setInlineButtons] = useState<any[]>();
	const [currentText, setCurrentText] = useState<string>("");
	const [currentUrl, setCurrentUrl] = useState<string>("");

	const getTasks = async () => {
		try {
			const res = await fetchGetTasks();
			if (res.data.tasks) {
				let tasks: ITask[] = [];
				res.data.tasks.map((item: ITask, _index: number) => {
					try {
						let task: ITask = {
							name: item.name,
							description: JSON.parse(String(item.description)),
							reward: item.reward,
							active: item.active,
							start: item.start,
							end: item.end,
							id: item.id,
						};
						tasks.push(task);
					} catch (e) {
						console.log(e, item);
					}
					console.log("task", item);
				});
				console.log("tasks", tasks);
				console.log(
					tasks.filter(
						(i: ITask) => i.active && i.description.type === ITaskType.daily
					)
				);
				setMessageAllEn(
					`You now have access to ${
						tasks.filter(
							(i: ITask) => i.active && i.description.type === ITaskType.daily
						).length
					} quests. Head over to the bot and start completing them quickly!`
				);
				setMessageAllRu(
					`Теперь у вас есть доступ к ${
						tasks.filter(
							(i: ITask) => i.active && i.description.type === ITaskType.daily
						).length
					} заданиям. Заходите в бота и начинайте их быстро выполнять!`
				);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const sendMessage = async (
		messageEn: string,
		messageRu: string,
		password: string,
		buttonTextEn?: string,
		buttonTextRu?: string,
		imageUrl?: string,
		inlineButtons?: { text: string; url: string }[]
	) => {
		try {
			const res = await fetchBroadcast(
				{ en: messageEn, ru: messageRu },
				password,
				{ en: buttonTextEn, ru: buttonTextRu },
				imageUrl,
				inlineButtons
			);
			if (res.data) {
				toast.success("Message sent!");
			}
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getTasks();
	}, []);

	const _handleImageChange = async (e: any) => {
		const files = (e.target as HTMLInputElement).files;
		if (files && files[0]) {
			readImage(files[0]);
			console.log(files[0]);
		}
	};

	function readImage(file: File) {
		readFileDataAsBase64(file).then((currentFile) => {
			console.log("file", currentFile);
			console.log(String(currentFile));
			setImageUrl(String(currentFile));
		});
	}

	const readFileDataAsBase64 = (file: any) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (event) => {
				resolve(event.target?.result);
			};

			reader.onerror = (err) => {
				reject(err);
			};

			reader.readAsDataURL(file);
		});
	};

	return (
		<div className={styles.content}>
			<div className={styles.block}>
				<div>
					<div className={styles.title}>Password</div>
					<input
						value={password}
						onChange={(ev) => setPassword(ev.target.value)}
						className={styles.input}
					/>
				</div>
				<div className={styles.generateBlock}>
					<div
						className={styles.btn}
						onClick={() =>
							sendMessage(
								messageAllEn,
								messageAllRu,
								password,
								buttonTextEn,
								buttonTextRu,
								imageUrl,
								inlineButtons
							)
						}
						style={{ width: "206px" }}
					>
						Broadcast Active Task
					</div>
					<div className={styles.generateText}>
						Clicking this button will generate a message displaying the current
						number of active tasks.
					</div>
				</div>
				<div>
					<div className={styles.title}>Message of all active quests en</div>
					<textarea
						value={messageAllEn}
						onChange={(ev) => {
							setMessageAllEn(ev.target.value);
						}}
						className={cl(styles.input, styles.message)}
					/>
				</div>
				<div>
					<div className={styles.title}>Message of all active quests ru</div>
					<textarea
						value={messageAllRu}
						onChange={(ev) => {
							setMessageAllRu(ev.target.value);
						}}
						className={cl(styles.input, styles.message)}
					/>
				</div>
				<div>
					<div className={styles.title}>Message en</div>
					<textarea
						value={messageEn}
						onChange={(ev) => {
							setMessageEn(ev.target.value);
						}}
						className={cl(styles.input, styles.message)}
					/>
				</div>
				<div>
					<div className={styles.title}>Message ru</div>
					<textarea
						value={messageRu}
						onChange={(ev) => {
							setMessageRu(ev.target.value);
						}}
						className={cl(styles.input, styles.message)}
					/>
				</div>
			</div>
			<div className={styles.block}>
				<div>
					<div className={styles.title}>Button text en</div>
					<input
						value={buttonTextEn}
						onChange={(ev) => {
							setButtonTextEn(ev.target.value);
						}}
						className={styles.input}
					/>
				</div>
				<div>
					<div className={styles.title}>Button text ru</div>
					<input
						value={buttonTextRu}
						onChange={(ev) => {
							setButtonTextRu(ev.target.value);
						}}
						className={styles.input}
					/>
				</div>
				<label htmlFor="upload-photo" className={styles.btn}>
					Choose Image
				</label>
				<input
					type="file"
					id="upload-photo"
					onChange={(e) => _handleImageChange(e)}
					style={{ display: "none" }}
				/>
				<img src={imageUrl} alt="" style={{ maxWidth: "200px" }} />
				{inlineButtons && (
					<div>
						{inlineButtons.map((item, index) => {
							return (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "20px",
										marginBottom: "14px",
									}}
								>
									<img
										src={trash}
										alt=""
										style={{ cursor: "pointer" }}
										onClick={() => {
											let buf = inlineButtons;
											buf.splice(index, 1);
											setInlineButtons([...buf]);
										}}
									/>
									<div>{index}</div>
									<div style={{ display: "flex", gap: "20px" }}>
										<div>{item.text}</div>
										<div
											style={{ cursor: "pointer" }}
											onClick={() => window.open(`${item.url}`)}
										>
											{item.url}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				)}
				<div>
					<div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
						<div>{inlineButtons && inlineButtons.length}</div>
						<div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
							<div>Text</div>
							<input
								type="text"
								value={currentText}
								onChange={(e) => setCurrentText(e.target.value)}
								className={styles.input}
							/>
							<div>Url</div>
							<input
								type="text"
								value={currentUrl}
								onChange={(e) => setCurrentUrl(e.target.value)}
								className={styles.input}
							/>
						</div>
					</div>
				</div>
				<div style={{ display: "flex", gap: "20px" }}>
					<div
						className={styles.btn}
						onClick={() => {
							if (inlineButtons !== undefined) {
								if (currentText !== "" && currentUrl !== "") {
									let buf = inlineButtons;
									buf.push({
										text: currentText,
										url: currentUrl,
									});
									setInlineButtons([...buf]);
								}
							} else {
								if (currentText !== "" && currentUrl !== "") {
									let buf = [];
									buf.push({
										text: currentText,
										url: currentUrl,
									});
									setInlineButtons([...buf]);
								}
							}
							setCurrentText("");
							setCurrentUrl("");
						}}
					>
						Add
					</div>
					<div
						className={styles.btn}
						onClick={() =>
							sendMessage(
								messageEn,
								messageRu,
								password,
								buttonTextEn,
								buttonTextRu,
								imageUrl,
								inlineButtons
							)
						}
						style={{ width: "277px" }}
					>
						Send a custom information message
					</div>
				</div>
			</div>
		</div>
	);
};
